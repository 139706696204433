<template>
  <div>
    <loading :enable="loading" />
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3 py-0">
        <b-col md="2" style="max-width: initial">
          <b-form-group label="Lotes de importações de hoje">
            <b-form-select v-model="filter.lot">
              <b-form-select-option
                  v-for="lot in lots"
                  :key="lot.id"
                  :value="lot.lot"
              >
                {{ lot.lot }} - {{ lot.created_at | moment("HH:mm") }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="2" style="max-width: initial">
          <b-form-group>
            <b-button @click="getAll()" variant="primary" class="mr-2" style="margin-top: 30px" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
              <i class="fas fa-search" v-if="!loading"></i> Pesquisar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-row>

    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <b-table
            responsive
            class="mt-3"
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Resultado encontrado</h4>
          </template>

          <template v-slot:cell(actions)="data">
            <div class="d-flex align-items-center justify-content-center">
              <a href="#" @click.prevent="showDetails(data.item.subsee_id)">Ver detalhes</a>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>

import Loading from "@/components/shared/loading.vue";
import LogsService from "@/services/logs/LogsService";
import logsService from "@/services/logs/LogsService";
import moment from "moment";

export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      items: [],
      lots: [],
      clientSearch: '',
      filter: {
        lot: null,
      },
      fields: LogsService.getFieldsTableResume(),
    }
  },
  async mounted() {
    await LogsService.getDailyLots().then((response) => {
      this.lots = response.data;
      this.filter.lot = this.lots[0].lot;
    }).finally(() => {
      this.getAll()
    });
  },
  methods: {
    moment() {
      return moment
    },
    async getAll() {
      this.loading = true;
      await logsService.getResume(this.filter).then((response) => {
        this.items = response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    showDetails(client) {
      this.$router.push(`/importer-logs/details/${this.filter.lot}/${client}`);
    }
  }
}
</script>